
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import QForm from 'quasar/src/components/form/QForm.js';
import { randomString } from '@/utils/helpers'
import { COLORS_LIST, WORKFLOW_TYPE } from '@/constants/vars'
import { EWorkflowType, IWorkflowState, WorkflowModel } from '@/components/workflow/workflow-model'

import cloneDeep from 'lodash/cloneDeep'
import WorkflowMixin from './mixins/WorkflowMixin.vue'
import logging from '@/utils/logging'
import dayjs from 'dayjs'
import ColorPickerDropdown from '../color/ui/ColorPickerDropdown.vue'

@Options({
  components: { ColorPickerDropdown },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class WorkflowStateFormModal extends mixins(WorkflowMixin) {
  @Prop({ default: {} })
  workflowStateData!: IWorkflowState

  @Prop({ default: {} })
  workflow!: WorkflowModel

  @Prop()
  modalVisible!: boolean

  WORKFLOW_TYPE = WORKFLOW_TYPE

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  requiredRule = {
    message: 'This field is required',
  }

  form: IWorkflowState = {
    label: 'New state',
    type: EWorkflowType.new,
    color: COLORS_LIST[0],
    value: randomString(),
    position: dayjs().unix(),
  }

  rules = {
    title: [this.requiredRule],
  }

  get isNew() {
    return !this.workflowStateData.value
  }

  get modalTitle() {
    return this.isNew ? 'Create new state' : 'Edit state'
  }

  onSubmitWorkflow() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        await this.doSaveWorkflow()
        this.onCancel()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async doSaveWorkflow() {
    if (!this.workflow) {
      throw new Error('Unknow workflow')
    }

    let states = cloneDeep(this.workflow.states) || []
    if (this.isNew) {
      states.push(this.form)
    } else {
      states = states.map((state: IWorkflowState) => {
        if (state.value === this.form.value) {
          return this.form
        }

        return state
      })
    }

    return this.updateWorkflow({ _id: this.workflow._id, states })
  }

  onCancel() {
    this.visible = false
  }

  async created() {
    this.form = { ...this.form, ...this.workflowStateData }
  }
}
