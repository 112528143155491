<template>
  <section class="q-pa-md">
    <div class="row no-wrap">
      <div>
        <h1 class="text-h4">{{ workspace.title }}</h1>
        <p class="text-subtitle2">Workspace workflow states setting</p>
      </div>
    </div>

    <div class="container-smaller q-mt-lg">
      <div class="q-my-md">
        <q-select
          option-value="_id"
          option-label="title"
          outlined
          dense
          options-dense
          v-model="workflowId"
          :options="workflows"
          label="Workflow"
          emit-value
          map-options
        />
      </div>

      <template v-for="flow in WORKFLOW_TYPE" :key="flow.value">
        <q-toolbar class="bg-grey text-white">
          <q-toolbar-title>{{ flow.label }}</q-toolbar-title>
        </q-toolbar>
        <q-list bordered separator class="rounded-borders bg-grey-1">
          <q-item v-for="(workflow, index) in getWorkflowByType(flow.value)" :key="index">
            <q-item-section>
              <span>
                <q-chip :style="{ background: workflow.color }" text-color="white">
                  {{ workflow.label }}
                </q-chip>
              </span>
            </q-item-section>
            <q-item-section side>
              <div class="q-gutter-sm">
                <q-btn size="sm" round icon="edit" @click="onEdit(workflow)" />
                <q-btn size="sm" color="red" round icon="delete" @click="onDelete(workflow)" />
              </div>
            </q-item-section>
          </q-item>
        </q-list>

        <q-space class="q-my-md" />
      </template>
      <div class="q-gutter-sm">
        <q-btn color="primary" icon="add" @click.prevent="modalWorkflowFormVisible = true" label="New state" />
        <q-btn no-caps flat icon="keyboard_backspace" @click.prevent="gotoWorkflows" label="Back to workflows list" />
      </div>
    </div>
  </section>
  <WorkflowStateFormModal
    v-if="modalWorkflowFormVisible"
    :workflowStateData="selected"
    :workflow="workflow"
    :modalVisible="modalWorkflowFormVisible"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'

import { maska } from 'maska'
import { WORKFLOW_TYPE } from '@/constants/vars'
import { EWorkflowType, IWorkflow, IWorkflowState, WorkflowModel } from '@/components/workflow/workflow-model'

import NotFound from '@/components/common/NotFound.vue'
import WorkflowStateFormModal from '@/components/workflow/WorkflowStateFormModal.vue'
import WorkflowMixin from '@/components/workflow/mixins/WorkflowMixin.vue'

@Options({
  components: {
    NotFound,
    WorkflowStateFormModal,
  },
  directives: { maska },
})
export default class WorkflowStatesSetting extends mixins(WorkflowMixin) {
  modalWorkflowFormVisible = false
  selected = {}

  WORKFLOW_TYPE = WORKFLOW_TYPE

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  get workflowId() {
    return this.$route.params.workflowId as string
  }

  set workflowId(value) {
    this.$router.push({
      name: 'workspace_board.workflow_states',
      params: {
        workflowId: value,
      },
    })
  }

  get workflows(): WorkflowModel[] {
    return this.$store.getters.workflows(this.workspaceId) || []
  }

  get workflow(): WorkflowModel | undefined {
    return this.workflows.find(_workflow => _workflow._id === this.workflowId)
  }

  get workflowStates(): IWorkflowState[] {
    return this.workflow?.states || []
  }

  getWorkflowByType(type: EWorkflowType) {
    return this.workflowStates.filter(state => state.type === type)
  }

  async onDelete(state: IWorkflowState) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const states = this.workflowStates.filter(_state => _state.value !== state.value)
        await this.updateWorkflow({ _id: this.workflowId, states })
      })
  }

  onEdit(workflow: IWorkflow) {
    this.modalWorkflowFormVisible = true
    this.selected = workflow
  }

  onCloseModal() {
    this.modalWorkflowFormVisible = false
    this.selected = {}
  }

  gotoWorkflows() {
    this.$router.push({ name: 'workspace_board.workflows' })
  }

  async created() {
    this.$meta.setMeta({ title: ['Workflow state setting'] })
    await this.getWorkflows()
  }
}
</script>
