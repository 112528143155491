import { render } from "./WorkflowStatesSetting.vue?vue&type=template&id=4d293ddc"
import script from "./WorkflowStatesSetting.vue?vue&type=script&lang=ts"
export * from "./WorkflowStatesSetting.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QSelect from 'quasar/src/components/select/QSelect.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QToolbar,QToolbarTitle,QList,QItem,QItemSection,QChip,QBtn,QSpace});
