
import { Options, mixins } from 'vue-class-component'

import { maska } from 'maska'
import { WORKFLOW_TYPE } from '@/constants/vars'
import { EWorkflowType, IWorkflow, IWorkflowState, WorkflowModel } from '@/components/workflow/workflow-model'

import NotFound from '@/components/common/NotFound.vue'
import WorkflowStateFormModal from '@/components/workflow/WorkflowStateFormModal.vue'
import WorkflowMixin from '@/components/workflow/mixins/WorkflowMixin.vue'

@Options({
  components: {
    NotFound,
    WorkflowStateFormModal,
  },
  directives: { maska },
})
export default class WorkflowStatesSetting extends mixins(WorkflowMixin) {
  modalWorkflowFormVisible = false
  selected = {}

  WORKFLOW_TYPE = WORKFLOW_TYPE

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  get workflowId() {
    return this.$route.params.workflowId as string
  }

  set workflowId(value) {
    this.$router.push({
      name: 'workspace_board.workflow_states',
      params: {
        workflowId: value,
      },
    })
  }

  get workflows(): WorkflowModel[] {
    return this.$store.getters.workflows(this.workspaceId) || []
  }

  get workflow(): WorkflowModel | undefined {
    return this.workflows.find(_workflow => _workflow._id === this.workflowId)
  }

  get workflowStates(): IWorkflowState[] {
    return this.workflow?.states || []
  }

  getWorkflowByType(type: EWorkflowType) {
    return this.workflowStates.filter(state => state.type === type)
  }

  async onDelete(state: IWorkflowState) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const states = this.workflowStates.filter(_state => _state.value !== state.value)
        await this.updateWorkflow({ _id: this.workflowId, states })
      })
  }

  onEdit(workflow: IWorkflow) {
    this.modalWorkflowFormVisible = true
    this.selected = workflow
  }

  onCloseModal() {
    this.modalWorkflowFormVisible = false
    this.selected = {}
  }

  gotoWorkflows() {
    this.$router.push({ name: 'workspace_board.workflows' })
  }

  async created() {
    this.$meta.setMeta({ title: ['Workflow state setting'] })
    await this.getWorkflows()
  }
}
